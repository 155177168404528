
.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .text-align-md-left {
    text-align: left;
  }

  .text-align-md-center {
    text-align: center;
  }

  .text-md-black {
    color: @landing-black;
  }
}

@media screen and (max-width: 450px) {
  .wordspace-1000-xs {
    word-spacing: 30000px;
  }
}
