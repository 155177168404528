.landing-text-100 {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 90%;
  color: #FFFFFF;
  @media screen and (min-width: 768px) {
    font-size: 100px;
    line-height: 100%;
  }
  @media screen and (min-width: 2000px) {
    font-size: 6vw;
  }
}

.landing-text-28-thin {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #FFFFFF;
  margin: 0;
  @media screen and (min-width: 768px) {
    font-size: 28px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 1.25vw;
  }
}

.landing-text-28-thin-black {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
  margin: 0;
  color: #0D181E;
  font-weight: 500;
  @media screen and (min-width: 768px) {
    font-size: 28px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 1.25vw;
  }
}

.landing-text-16 {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #FFFFFF;
  margin: 0;
  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 100%;
  }
  @media screen and(min-width: 2000px) {
    font-size: 1vw;
  }
}

.landing-text-46 {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.3px;
  color: #FFFFFF;
  margin: 0;
  @media screen and (min-width: 768px) {
    font-weight: 700;
    font-size: 46px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 3vw;
  }
}

.landing-text-36-black {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 110%;
  letter-spacing: -0.3px;
  color: #0D181E;
  margin: 0;
  @media screen and (min-width: 768px) {
    font-weight: 700;
    font-size: 36px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 2vw;
  }
}

.landing-text-36 {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 110%;
  letter-spacing: -0.3px;
  color: #FFFFFF;
  margin: 0;
  @media screen and (min-width: 768px) {
    font-weight: 700;
    font-size: 36px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 2vw;
  }
}

.landing-text-20 {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #FFF;
  margin: 0;
  @media screen and (min-width: 768px) {
    font-size: 20px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 1vw;
  }
}

.landing-text-20-thin {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #FFF;
  margin: 0;

  @media screen and (min-width: 768px) {
    font-size: 20px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 1vw;
  }
}

.landing-text-16-green {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: @landing-green;
  text-transform: uppercase;
  margin: 0;
  @media screen and (min-width: 768px) {
    text-transform: none;
    font-size: 16px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 0.85vw;
  }
}

.landing-text-14 {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.3px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  @media screen and (min-width: 2000px) {
    font-size: 0.8vw;
  }
}

.landing-text-22 {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 110%;
  letter-spacing: -0.3px;
  color: #FFFFFF;
  margin: 0;
  @media screen and (min-width: 2000px) {
    font-size: 1vw;
  }
}

.landing-text-24 {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.3px;
  color: #FFFFFF;
  margin: 0;
  @media screen and (min-width: 2000px) {
    font-size: 1.2vw;
  }
}

.landing-text-28 {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 110%;
  color: #FFFFFF;
}
