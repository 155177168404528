.big-switch {
  width: 300px;
  height: 150px;

  .ant-switch-handle {
    top: 4px;
    left: 4px;
    width: 142px;
    height: 142px;
  }

  .ant-switch-handle::before {
    border-radius: 50%;
  }

  svg {
    width: 138px;
    height: 138px;
  }
}

.big-switch.ant-switch-checked {
  .ant-switch-handle {
    left: calc(100% - 142px - 4px);
  }
}


.medium-switch {
  width: 160px;
  height: 80px;

  .ant-switch-handle {
    top: 3px;
    left: 3px;
    width: 74px;
    height: 74px;
  }

  .ant-switch-handle::before {
    border-radius: 50%;
  }

  svg {
    width: 71px;
    height: 71px;
  }
}

.medium-switch.ant-switch-checked {
  .ant-switch-handle {
    left: calc(100% - 74px - 3px);
  }
}
