.mt--16 {
  margin-top: -16px;
}

.mt--20 {
  margin-top: -20px;
}

.mt--40 {
  margin-top: -40px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-40 {
  margin-bottom: 50px;
}

.mb-50 {
  margin-bottom: 40px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-16 {
  margin-left: 16px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

@media screen and (min-width: 768px) {
  .mb-md-210 {
    margin-bottom: 210px;
  }

  .mb-md-110 {
    margin-bottom: 110px;
  }

  .mb-md-60 {
    margin-bottom: 60px;
  }

  .ml-md-32 {
    margin-left: 32px;
  }

  .mb-md-32 {
    margin-bottom: 32px;
  }

  .mb-md-24 {
    margin-bottom: 24px;
  }

  .mt-md-100 {
    margin-top: 100px;
  }

  .mt-md-0 {
    margin-top: 0;
  }

  .mt-md-20 {
    margin-top: 20px;
  }

  .mb-md-0 {
    margin-bottom: 0;
  }
}

