.main-blur-container {
  background: rgba(124, 124, 124, 0.3);
  backdrop-filter: blur(75px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  @media screen and (min-width: 768px) {
    padding: 32px;
  }
}

.iphone-container {
  position: absolute;
  transform: rotate(-15deg);
  background: linear-gradient(180deg, #4E4E4E 0%, #302E2C 100%);
  box-shadow: -18px 18px 2px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.iphone-shadow {
  box-shadow: -6px 7px 1px rgba(0, 0, 0, 0.3);
}

.laptop-container {
  position: absolute;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 15px;
  filter: drop-shadow(-39.01px 54.6108px 2px rgba(0, 0, 0, 0.15));
  border-radius: 20px;
  transform: rotate(-15deg);
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.laptop-shadow {
  filter: drop-shadow(-12px 12px 1px rgba(0, 0, 0, 0.2));
}

.companies-img {
  cursor: pointer;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.companies-img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
}

.landing-input {
  display: flex;
  flex-direction: column;

  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #FFFFFF;
  opacity: 0.7;
  @media screen and (min-width: 2000px) {
    font-size: 1vw;
  }
  @media screen and (min-width: 768px) {
    font-size: 20px;
  }

  input {
    background: none;
    border: none;
    border-bottom: 2px solid #0E2C35;
    outline: none;
    height: 32px;
  }

  input:-webkit-autofill {
    border: none;
    border-bottom: 2px solid #0E2C35;
    -webkit-text-fill-color: #FFFFFF;
    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:focus {
    outline: none;
    border-bottom: 2px solid #164856FF;
  }
}

.dark-text-container {
  padding: 28px 20px;
  background: #0E2C35;
  box-shadow: 0 34px 34px -30px rgba(0, 0, 0, 0.55);
  border-radius: 20px;
}

.green-text-container {
  height: 100%;
  padding: 20px;
  background: @landing-green;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.step-container {
  padding: 4px 10px;
  background: @landing-black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  //width: 110px;
}

.info-container {
  position: relative;
  overflow: hidden;
  padding: 20px;

  background: @landing-black;
  box-shadow: 0 40px 20px -40px @landing-black;
  border-radius: 20px;
}

.pricing-container {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding: 10px;

  .pricing-sub-container {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 28px;
    padding: 10px;
  }
}


.step2-container {
  position: absolute;
  background: @landing-green;
  border-radius: 20px;
  padding: 2% 9%
}

.implementation-container {
  height: 100%;
  padding: 14px;

  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(14, 44, 53, 0.6);
  box-shadow: 0 80px 120px -80px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(25px);

  border-radius: 20px;
}

.basic-info-container {
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 14px 90px 140px -110px rgba(0, 0, 0, 0.55);
  border-radius: 20px;
  backdrop-filter: blur(5px);
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.background-none {
  background: none;
}

.background-black {
  background: @landing-black;
}

@media screen and (min-width: 768px) {
  .block-md {
    display: block;
  }

  .flex-md {
    display: flex;
  }

  .hidden-md {
    display: none;
  }

  .background-md-gray {
    background: rgba(14, 44, 53, 0.1);
  }

  .background-md-none {
    background: none;
  }
}


