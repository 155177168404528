.mk-top-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.mk-bottom-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}
