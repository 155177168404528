footer {
  background-color: @landing-black;

  .background-success {
    padding: 66px 46px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
  }

  @media screen and (max-width: 767px) {
    .background-md-footer {
      background: rgba(255, 255, 255, 0.04);
      backdrop-filter: blur(5px);
      border-radius: 20px;
    }
  }
}
