.ant-layout-content {
  background: white;
}

.pageTitle {
  margin: 15px 0;
  .results {
    margin: 10px 0 0 12px;
    color: #CDCDD2;
  }
}

.container-main {
  max-width: 1600px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 15px;
}

.container-simple-main {
  max-width: 1600px;
  min-height: 100vh;
  margin: 0 auto;
}
