.pb-80 {
  padding-bottom: 80px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-56 {
  padding-bottom: 56px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-36 {
  padding-bottom: 36px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-20 {
  padding-top: 20px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.p-40 {
  padding: 40px;
}

.p-16 {
  padding: 16px;
}

@media screen and (min-width: 768px) {
  .pb-md-50 {
    padding-bottom: 50px;
  }

  .pb-md-40 {
    padding-bottom: 40px;
  }

  .pb-md-80 {
    padding-bottom: 80px;
  }

  .pt-md-80 {
    padding-top: 80px;
  }

  .pt-md-120 {
    padding-top: 120px;
  }

  .pt-md-100 {
    padding-top: 100px;
  }

  .py-md-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .py-md-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .py-md-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .p-md-40 {
    padding: 40px;
  }

  .p-md-0 {
    padding: 0;
  }
}

