button.ant-dropdown-open {
  width: 350px;
  @media screen and (max-width: 1350px) {
    width: 250px;
  }
  @media screen and (max-width: 800px) {
    width: 200px;
  }
  @media screen and (max-width: 768px) {
    width: 190px;
  }
}

.consultant-dropdown {
  .ant-dropdown-menu {
    overflow-y: auto;
    height: auto;
    max-height: 510px;

    li {
      &.ant-dropdown-menu-item {
        padding: 5px 12px 5px 0;
        border-bottom: 1px solid #F2F2F2;
        width: 350px;
        @media screen and (max-width: 1350px) {
          width: 250px;
        }
        @media screen and (max-width: 800px) {
          width: 200px;
        }

        @media screen and (max-width: 768px) {
          width: 190px;
        }

        .ant-dropdown-menu-title-content {
          & label {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
