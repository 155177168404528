.b-20 {
  border-radius: 20px;
}

.br-16 {
  border-radius: 16px;
}

.br-5 {
  border-radius: 5px;
}

.br-bottom-20 {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.br-top-20 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

@media screen and (min-width: 768px) {
  .br-bottom-md-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .br-top-md-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .b-md-0 {
    border-radius: 0;
  }
}
