.submit-button {
  position: relative;
  margin-top: 60%;
}

.consultant-button {
  margin-top: 50px;
  padding: 0 50px;
  min-width: 250px;
}

.consultant-button-complete {
  margin: 20px 0;
  padding: 0 25px;
}

.consultant-button-step {
  margin-top: 30px;
  padding: 0 20px;

  &.previous {
    margin-right: 15px;
  }

  &.next {
    margin-right: 15px;
  }
}

.reset-btn-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 6px;

  button {
    background: rgba(205, 205, 205, 0.7);
  }
}

.default-landing-btn {
  font-family: 'Prompt';
  font-weight: 600;
  line-height: 130%;
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 16px;

  border: 1px solid @landing-green;
  background: @landing-green;
  border-radius: 16px;
  padding: 14px 30px;
  height: auto;
  width: auto;
  cursor: pointer;
  @media screen and (min-width: 2000px) {
    font-size: 0.85vw !important;
  }
}

.default-landing-btn:hover:not([disabled]) {
  color: @landing-green;
  background: @landing-black;
  box-shadow: 0 10px 40px -50px rgba(15, 48, 9, 0.5);
}

.default-landing-btn:disabled {
  opacity: 0.3;
}

.secondary-landing-btn {
  color: @landing-green;
  background: @landing-black;
  box-shadow: 0 10px 40px -50px rgba(15, 48, 9, 0.5);

  font-family: 'Prompt';
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;

  border: 1px solid @landing-green;
  border-radius: 16px;
  padding: 14px 30px;
  height: auto;
  width: auto;
  cursor: pointer;
  @media screen and (min-width: 2000px) {
    font-size: 0.85vw;
  }
}

.secondary-landing-btn:hover:not([disabled]) {
  color: #FFFFFF;
  background: @landing-green;
}

.secondary-landing-btn:disabled {
  opacity: 0.3;
}

.no-styles {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
