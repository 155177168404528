@import '~antd/dist/antd.less';

@import "./layout/index";
@import "./switch/index";
@import "./variables";
@import "./ui";
@import "./text";
@import "./general/padding";
@import "./general/margin";
@import "./general/border";
@import "./general/text";
@import "./general/flex";

body {
  background-color: #F1F1F1;

  #root {
    overflow: hidden;
  }
}

@primary-color: #000;@border-radius-base: 4px;