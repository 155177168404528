.ant-layout-sider {
  background: #2D2D2D;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.ant-menu-title-content a {
  color: #7B7B7b;
}

.user-name {
  color: #fff
}

.logo {
  text-transform: uppercase;
  color: @white-color;
  background: #0E0E0E !important;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #2D2D2D !important;

  .ant-menu-item-selected, .ant-menu-item-selected .ant-menu-submenu-open {
    background: #444444 !important;
  }

  .ant-menu-submenu-active {
    background: #444444 !important;
  }
}
