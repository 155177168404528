header {
  background-color: @landing-black;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  white-space: nowrap;
  @media screen and (min-width: 768px) {
    background: rgba(13, 24, 30, 0.8);
    backdrop-filter: blur(5px);
  }

  a {
    font-family: 'Prompt', sans-serif;
    font-size: 16px;

    text-align: center;
    text-transform: uppercase;
    color: #fff;
    @media screen and (min-width: 2000px) {
      font-size: 0.85vw;
    }
    @media screen and (min-width: 768px) {
      font-size: 12px;
    }
  }

  a:hover {
    color: @landing-green;
  }

  .logo-container {
    margin: 14px 0;
    @media screen and (min-width: 768px) {
      margin: 25px 0;
    }
  }

  .burger-container {
    height: 100%;
    overflow: hidden;
    animation: all;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}
